<style>
.ivu-tree ul li {
  margin: 5px 0 !important;
}
</style>
<template>
  <div class="side-controller">
    <div class="side-header">
      <div class="side-header-title">
        {{ menu.sub.name }}
      </div>
    </div>
    <div class="side-nav">
      <router-link
        class="nav-cell"
        :class="[getRouterPath == item.path ? 'nav-active' : '']"
        :to="{ name: item.name }"
        v-for="(item, idx) in menu.child"
        :key="idx"
      >
        <Badge :count="getMenuBadgeCount(item.path)">
          {{ item.name }}
        </Badge>
        <!-- {{ item.name }} -->
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "side",
  props: ["menu"],
  data() {
    return {
      treeData: [],
      activePath: "",
    };
  },
  methods: {
    ...mapActions(["loginOut", "RefreshMenusBadges"]),
    getMenuBadgeCount(path) {
      //let count = this.$store.dispatch("getMenusBadgeCount", path);
      let count = 0;
      let badges = this.$store?.getters?.menusBadges;
      badges?.forEach((element) => {
        if (element?.path?.startsWith(path)) count = +element?.count;
      });
      return count;
    },
    // selectTree(d) {
    //     console.log(d)
    //     if(d.length <= 0 ) return false;
    //     if(this.routerName !== d[0].name){
    //         this.$router.replace({
    //             name: d[0].name
    //         })
    //     }
    // }
  },
  mounted() {
    // const list = rou.getRoutes();
    // if(list.includes(this.routerName)){
    //     let myId = this.routerName;
    //     const routes = rou.init();
    //     const route = routes.find(item => {
    //         return item.name === myId;
    //     })
    //     console.log(route)
    //     route.selected = true;
    //     this.treeData = routes
    // }else{
    //     this.treeData = rou.init()
    // }
    // console.log(this.routerName)
  },
  computed: {
    getRouterPath() {
      let arr = this.$router.$myRouter.getNowPathValue(this.$route);
      if (arr.length === 3) {
        return arr[2].path;
      } else {
        return "";
      }
    },
  },
  created() {
    this.RefreshMenusBadges();
  },
};
</script>

<style  lang="less">
.side-controller {
  width: 200px;
  height: auto;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  .side-header {
    position: relative;
    width: 180px;
    height: 34px;
    padding-right: 20px;
    margin-right: 20px;
    background: #fff;
    border-left: 4px solid #0c64eb;
    border-radius: 4px 2px 2px 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
      0 2px 6px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 6px 0 rgba(0, 0, 0, 0.045);

    &-title {
      padding: 0 5px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 700;
      line-height: 32px;
      color: #0c64eb;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:after {
      position: absolute;
      top: -1px;
      right: -8px;
      display: block;
      width: 0;
      height: 0;
      content: " ";
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 18px 0 18px 8px;
    }
  }

  .side-nav {
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ivu-badge-count {
      height: 16px;
      line-height: 14px;
      padding: 0 2px;
      font-size: 8px;
    }
  }

  .nav-cell {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    margin: 5px 0;
    text-align: center;

    &:hover {
      color: #ffffff;
      background: #1e7ee8;
    }
  }

  .nav-active {
    color: #ffffff;
    background: #1e7ee8;
    border-radius: 2px;
  }
}
</style>
