.size {
  width: 100%;
  height: 100%;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #3c4353;
}
#app {
  width: 100%;
  height: 100%;
}
