.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /*height: 40px;*/
  height: 0px;
  background: #fff;
  border-top: 1px solid #eff1f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-container {
  font-size: 13px;
  color: #3c4353;
  width: 100%;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.footer-container .breadcrumb {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.footer-container .powered-by {
  position: absolute;
  padding-right: 50px;
  right: 0;
  bottom: 0;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
}
