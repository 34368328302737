
.side-controller {
  width: 200px;
  height: auto;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  .side-header {
    position: relative;
    width: 180px;
    height: 34px;
    padding-right: 20px;
    margin-right: 20px;
    background: #fff;
    border-left: 4px solid #0c64eb;
    border-radius: 4px 2px 2px 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05),
      0 2px 6px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 6px 0 rgba(0, 0, 0, 0.045);

    &-title {
      padding: 0 5px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 700;
      line-height: 32px;
      color: #0c64eb;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:after {
      position: absolute;
      top: -1px;
      right: -8px;
      display: block;
      width: 0;
      height: 0;
      content: " ";
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 18px 0 18px 8px;
    }
  }

  .side-nav {
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ivu-badge-count {
      height: 16px;
      line-height: 14px;
      padding: 0 2px;
      font-size: 8px;
    }
  }

  .nav-cell {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    margin: 5px 0;
    text-align: center;

    &:hover {
      color: #ffffff;
      background: #1e7ee8;
    }
  }

  .nav-active {
    color: #ffffff;
    background: #1e7ee8;
    border-radius: 2px;
  }
}
