
.size {
    width: 100%;
    height: 100%;
}

html, body {
    .size;
    margin: 0;
    padding: 0;
    color: #3c4353;
}

#app {
    .size;
}
