.full-active {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  top: -1px;
}
.sub-active {
  border-radius: 4px;
  cursor: pointer;
  color: #0c64eb;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  top: -1px;
}
.header-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-container .header {
  width: 100%;
  min-width: 1200px !important;
  height: auto;
  background: seagreen;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header-container .header .ivu-badge-count {
  height: 16px;
  line-height: 14px;
  padding: 0 2px;
  font-size: 8px;
}
.header-container .header-main {
  width: 100%;
  height: 50px;
  color: #ffffff;
  background: #1183fb linear-gradient(-90deg, #0a48d1 0, #1183fb 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-container .header-main .logo {
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 20px;
  font-weight: bold;
}
.header-container .header-main .full-nav {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-container .header-main .full-nav .full-nav-cell {
  width: auto;
  height: 100%;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  background: rgba(0, 0, 0, 0);
}
.header-container .header-main .full-nav .full-nav-cell:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  top: -1px;
}
.header-container .header-main .full-nav .full-nav-cell-active {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  top: -1px;
  font-weight: bold;
}
.header-container .header-main .nav-search {
  position: absolute;
  right: 20px;
  top: 10px;
  width: auto;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-container .header-sub {
  width: 100%;
  height: 50px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.header-container .header-sub .sub-nav {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-container .header-sub .sub-nav .sub-nav-cell {
  width: auto;
  height: 100%;
  color: #3c4353;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  position: relative;
}
.header-container .header-sub .sub-nav .sub-nav-cell:hover {
  border-radius: 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  top: -1px;
  color: #0c64eb;
  font-weight: bold;
}
.header-container .header-sub .sub-nav .sub-nav-cell-title {
  color: #000;
  font-weight: bold;
  position: relative;
  top: -1px;
}
.header-container .header-sub .sub-nav .sub-nav-cell-title:hover {
  color: #000;
  background: #fff;
  cursor: default;
}
.header-container .header-sub .sub-nav .divider {
  display: block;
  width: 2px;
  height: 20px;
  margin: 9px 5px;
  background-color: rgba(0, 0, 0, 0.05);
}
.header-container .header-sub .sub-nav .sub-nav-cell-active {
  border-radius: 4px;
  cursor: pointer;
  color: #0c64eb;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  top: -1px;
  background: rgba(0, 0, 0, 0);
  font-weight: bold;
}
.header-container .header-sub-breadcrumb {
  position: absolute;
  left: 2.5%;
  top: 50%;
  width: auto;
  height: 40px;
  /*background: slategrey;*/
  margin-top: -20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
